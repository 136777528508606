<template>

    <div class="artikeldetail" :key="article.id" :title="article.title">
        <h1 v-if="article.title" class="detail-title text-center">{{ article.title }}</h1>
        <p v-if="article.teaser" class="lead detail-teaser">{{ article.teaser }}</p>

        <img v-if="article.img" :src="getImgUrl(article.img)" class="detail-image">
        <img v-else src="https://dummyimage.com/600x400/000/fff" class="detail-image">

        <p v-if="article.body" class="detail-body narrow"><span v-html="article.body"></span></p>
    </div>

</template>

<script>
import json from '../assets/json/articles.json'

// @ is an alias to /src
export default {
    name: 'ArtikelDetail',
    props: {
        slug: String
    },
    data() {
        return {
            articles: json,
            article: undefined
        }
    },
    /*computed: {
        id () {
            return this.$route.params.id // From route
        }
    },*/
    created() {
        this.getPost();
    },
    watch: {
        $route(to, from) {
            // react to route changes...
            this.getPost();
            return to, from;
        }
    },
    methods: {
        getPost() {
            let data = this.articles.filter(item => {
                return item.slug == this.slug
            })
            this.article = data[0]
        },
        getImgUrl: function(filename){
            return require('../assets/img/' + filename)
        }
    }
}
</script>
